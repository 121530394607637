import store from '../store/index'
import { Message } from 'element-ui';
// 存数据
function setItem (key,value) {
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  window.sessionStorage.setItem(key, value)
}
// 取数据
function getItem (key) {
  const data = window.sessionStorage.getItem(key)
  try {
    return JSON.parse(data)
  } catch (err) {
    return data
  }
}
// 删除数据
function removeItem (key) {
 window.sessionStorage.removeItem(key)
}

// 数组查找ID
function arrFindID (id,arr) {
  let index = arr.findIndex(e=> { return e == id})
  if (index == -1) {
    arr.push(id)
  }else {
    arr.splice(index,1)
  }
  return arr
}
var source = ''
function sourcea (value) {
  this.close()        // http://192.168.1.7:8081/  http://test.taiyijimu.com:8091/
  //  source = new EventSource('http://www.taiyijimu.com:9999/' + 'sendMsg?sign='+value)
   source.addEventListener('message', e => {
         
         let ac =  JSON.parse(e.data)
     if(ac.type == 1){
      // this.resetSetItem('vale',ac.data);
      store.commit( 'setId' ,(ac.data) )
      sessionStorage.setItem('vale',ac.data)
      sessionStorage.setItem('type',1)
     } 
    
      if(ac.type== 3){
        store.commit( 'setIda' ,(ac.data) )
        sessionStorage.setItem('valb',ac.data)
        sessionStorage.setItem('typea',3)
        }
        if(ac.type== 4){
          store.commit( 'setIdb' ,(ac.data) )
          sessionStorage.setItem('valc',ac.data)
          sessionStorage.setItem('typea',4)
          }
          if(ac.type== 5){
            store.commit( 'setIdc' ,(ac.data) )
            sessionStorage.setItem('vald',ac.data)
            sessionStorage.setItem('typea',5)
            }
            if(ac.type== 2){
              store.commit( 'setIdd' ,(ac.data) )
            sessionStorage.setItem('vala',ac.data)
            sessionStorage.setItem('typea',2)
            }
          if(ac.type== 0){
            this.close()
                }

    
     
}, false);
 }

 function close (){
   if(source == ''){
     return
   }else{
    source.close()
   }

 }

         // 成功提示
function success () {
  Message({
    message: '操作成功',
    type: 'success'
  });
}
        // 失败提示
function error (value) {
  Message.error(value);
 }

 
var httpUrl = 'http://backstage.taiyijimu.com:9999/'//see链接地址域名
 // var httpUrl =:' http://192.168.1.9:8081/',
    // var httpUrl=' http://www.taiyijimu.com:9999/',

export default {
  setItem,
  getItem,
  removeItem,
  arrFindID,
  httpUrl,
  sourcea,
  close,
  error,
  success

}

