
import Vue from 'vue'
import Vuex from 'vuex'
import common from '../assets/common'

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        token: common.getItem('token'),
        treeList: {},
        ida: ' ',
        idb: ' ',
        idc: ' ',
        idd: ' ',
        id: ' ',
        menus: [
        ],
        all: [],
        shrinkFlag: false,
        source:'temu',
    },
    mutations: {
        setSource(state,source){
            state.source = source
        },
        setId(state, id) {
            state.id = id
        },
        setIda(state, id) {
            state.ida = id
        },
        setIdb(state, id) {
            state.idb = id
        },
        setIdc(state, id) {
            state.idc = id
        },
        setIdd(state, id) {
            state.idd = id
        },

        setPrint(state, all) { //设置参数
            state.all = all;
        },
        setToken(state, data) {
            // common.setItem('token',data)
        },
        setTreeList: (state, treeList) => {
            state.treeList = treeList
        },
        setMenus: (state, menus) => {
            state.menus = menus
        },
        updataShrinkFlag: (state, data) => {
            state.shrinkFlag = data
        }

    },
    actions: {
        logout() {
            // return new Promise (resolve=>{
            //   logout().then(res=>{
            //     sessionStorage.clear()
            //     router.replace('/login').catch(err=>{})
            //     resolve()
            //   })
            // }).catch(err=>{})


        }
    },
    modules: {

    }
})
