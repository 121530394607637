import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/index'),
        meta: {
            title: '嗨数云数据丨跨境选品和数据分析'
        },
        redirect: { path: '/buffer', query: { type: 1, status: 1 } }
    },

    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login'),
        meta: {
            title: '嗨数云数据丨跨境选品和数据分析-登录'
        }
    },
    {
        path: '/permission',
        name: 'permission',
        component: () => import('@/views/login/permission'),
        meta: {
            title: '嗨数云数据丨跨境选品和数据分析-隐私协议'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/login/register'),
        meta: {
            title: '嗨数云数据丨跨境选品和数据分析-登录'
        }
    },
    {
        path: '/index',
        component: () => import('@/views/index'),
        meta: {
            title: '嗨数云数据丨跨境选品和数据分析'
        },
        children: [
            {
                path: '/home',
                name: 'home',
                redirect: { path: '/buffer', query: { type: 1, status: 1 } },
                component: () => import('@/views/home'),
                meta: {
                    title: '嗨数云数据丨跨境选品和数据分析-首页'
                },
                hidden:true,
            },
            {
                path: '/workbench',
                component: () => import('@/views/workbench'),
                meta: {
                    title: '嗨数云数据丨跨境选品和数据分析-工作台'
                },

            },
            {
                path: '/workbench/details',
                component: () => import('@/views/workbench/details'),
                meta: {
                    title: '嗨数云数据丨跨境选品和数据分析-详情'
                },
            },


            {
                path: '/pd',
                component: () => import('@/views/pd'),
                meta: {
                    title: '嗨数云数据丨跨境选品和数据分析'
                },

            },
            {
                path: '/shopdeild',
                component: () => import('@/views/pd/shop'),
                meta: {
                    title: '嗨数云数据丨跨境选品和数据分析'
                },

            },
            {
                path: '/productid',
                component: () => import('@/views/pd/product'),
                meta: {
                    title: '嗨数云数据丨跨境选品和数据分析'
                },

            },
            {
                path: '/buffer',
                component: () => import('@/views/buffer'),
                meta: {
                    title: '嗨数云数据丨跨境选品和数据分析'
                },

            },
            {
                path: '/jp',
                component: () => import('@/views/jp/index'),
                meta: {
                    title: '嗨数云数据丨竞品竞店'
                },
            },
            ,
            {
                path: '/collect',
                component: () => import('@/views/collect/index'),
                meta: {
                    title: '嗨数云数据丨我的收藏'
                },
            },

        ]
    },
    {
        path: '*',
        component: () => import('@/views/404.vue'),
        redirect: '/404'
    },
]



const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if(to.meta.title) {
        document.title = to.meta.title
    }
    //1.如果用户访问/login的请求,应该直接放行
    if(to.path === '/login') return next()

    //2.不是访问的登录页面,所以判断用户是否登录. 判断依据token
    // let token = window.sessionStorage.getItem("token")

    //3.如果!token  没有值,则执行if之后的操作
    // if(!token)  return next("/login")

    //4.如果代码执行到这一行,说明用户已经登录.则放行
    next()
})

// router.beforeEach((to, from, next) => {
//   let token = window.sessionStorage.getItem('token')
//   if (token ) {
//     next()
//   }else {
//     if (to.path == '/login' ) {
//       next()
//     } else {
//       next('/login')
//     }
//   }

// });


export default router
