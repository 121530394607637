import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/index.less'
import './assets/fonts/iconfont.css'
import 'default-passive-events' // 解决警告
import Common from './assets/common'
// 引入echarts
import * as echarts from 'echarts';
// import Pagination from "@/components/Pagination";

Vue.use(ElementUI);
// main.js
import jsCookie from 'js-cookie'
Vue.prototype.$cookie = jsCookie;  // 在页面里可直接用 this.$cookie 调用


import * as  Base64 from 'js-base64'
Vue.use(Base64)

// 挂载
Vue.prototype.$echarts = echarts
// 全局方法挂载
Vue.prototype.common = Common;

// 全局组件挂载
// Vue.component('Pagination', Pagination)
//格式化销量
Vue.filter(
    'formatSales', function(value) {
        let num = parseInt(value);
        if(num >= 100000) {
            return '10w+'
        } else if(num >= 10000) {
            let wan = parseInt(num / 10000)
            return wan + 'w+';
        } else if(num >= 1000) {
            let qian = parseFloat(num / 1000).toFixed(1)
            if(qian == 1) {
                return '1k+'
            } else {
                return qian + 'k+';
            }
        } else {
            return num
        }
    }
)
//decode
Vue.filter(
    'decode', function(value, ...args) {
        var arr = args;
        let index = arr.indexOf(value);
        if(index !== -1 && index < arr.length - 1) {
            return arr[index + 1];
        } else {
            return arr[arr.length - 1];
        }
    }
)
//金额千分
Vue.filter(
    'formatMoney', function(money) {
        let result = money;
        if(money) {
            var parts = money.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if(parts.length > 1) {
                result = parts.join(".")
            } else {
                result = parts[0]
            }
        }
        return result;
    }
)
Vue.filter(
    'formatPercent', function(value) {
        if(value === null) {
            return '--';
        }
        return value  + '%';
    }
)
// 阿里云处理图片缩放问题
Vue.filter('image', function(value, ...args) {
    if(typeof value == 'string') {
        if(value.includes('oss-cn-hangzhou') || value.includes('hishowstatic')) {
            let str = '';
            if(args.length == 2) {
                str = '?x-oss-process=image/resize,';
                let width = 'w_' + args[0] + ',';
                let height = 'h_' + args[1] + '';
                str = str + width + height;
            }
            return value + str;
        }
    }
    return value;
});
Vue.config.productionTip = false
new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
}).$mount('#app')

/* 
  使用的插件
  @riophae/vue-treeselect

 */
